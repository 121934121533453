import * as React from "react"
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'

import ImageGallery from '../shared/ImageGallery'
import Footer from '../shared/Footer'
import Header from '../shared/Header'
import Layout from '../shared/Layout'
import Page from '../shared/Page'

import { GET_PHOTOS } from "../apollo/getPhotosQuery"


// markup
const IndexPage = () => {
  const { data } = useQuery(GET_PHOTOS, {
    variables: { page: 'home' },
  })

  return (
    <Layout>
      <Helmet title="onedavila.com | Home" defer={false} />
      <Header />
      <Page>
        {data && <ImageGallery items={data.photosCollection.items?.[0]?.photoGalleryCollection?.items} />}
      </Page>
      <Footer />
    </Layout>
  )
}

export default IndexPage
